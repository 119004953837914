import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import DB from "../services/firestoredb";
import React, { useEffect, useRef } from 'react';

const CardNetworkLookup = () => {
  const [formData, setFormData] = useState({
    LegalName: "",
    DBAName: "",
    BusinessCategory: "",
    IsEcommerce: "",
    SearchCriteria: "",
    Address1: "",
    Address2: "",
    City: "",
    Country: "",
    PSCode: "",
    PhoneNumber: "",
    Email: "",
    Web: "",
    CFirstName: "",
    CMiddleName: "",
    CLastName: "",
    CMail: "",
    CAddress1: "",
    CAddress2: "",
    CPSCode: "",
    CCity: "",
    CFirstName2: "",
    CMiddleName2: "",
    CLastName2: "",
    CMail2: "",
    CAddress12: "",
    CAddress22: "",
    CPSCode2: "",
    CCity2: "",
    CCountry2: "",
    CPhone2: "",
    CFirstName3: "",
    CMiddleName3: "",
    CLastName3: "",
    CMail3: "",
    CAddress13: "",
    CAddress23: "",
    CPSCode3: "",
    CCity3: "",
    CCountry3: "",
    CPhone3: "",
    CFirstName4: "",
    CMiddleName4: "",
    CLastName4: "",
    CMail4: "",
    CAddress14: "",
    CAddress24: "",
    CPSCode4: "",
    CCity4: "",
    CCountry4: "",
    CPhone4: "",
    CFirstName5: "",
    CMiddleName5: "",
    CLastName5: "",
    CMail5: "",
    CAddress15: "",
    CAddress25: "",
    CPSCode5: "",
    CCity5: "",
    CCountry5: "",
    CPhone5: "",
    CCountry: "",
    CPhone: "",
    APIResponse1: "",
    PQReference: "",
    APIResponse2: "",
    TotalNoContact: ""
  });

  // const [error, setError] = useState('');
  const [error, setError] = useState({});
  const [numContacts, setNumContacts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaValue1, setCaptchaValue1] = useState(null);
  const [captchaVal, setCaptchaVal] = useState(null);

  const recaptchaRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "PhoneNumber") {
      const filteredValue = value.replace(/[^0-9+,]/g, '');
      setFormData({
        ...formData,
        PhoneNumber: filteredValue,
      });
    } else if (name === "Email") {
      setFormData({
        ...formData,
        Email: value,
      });
    } else if (name === "CMail") {
      setFormData({
        ...formData,
        CMail: value,
      });
    } else if (name === "TotalNoContact") {
      const numericValue = Number(value);
      if (value && (numericValue < 1 || numericValue > 5)) {
        setError({ ...error, TotalNoContact: 'Please enter a number between 1 and 5' });
      } else {
        setError({ ...error, TotalNoContact: '' });
        setNumContacts(numericValue);
        setFormData({ ...formData, [name]: value });
      }
      // setNumContacts(numericValue);
      // setFormData({ ...formData, [name]: value });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    //setError('');
  };

  const totalNoContact = parseInt(formData.TotalNoContact) || 0;
  const principals = [];

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setError('');
  };

  // useEffect(() => {
  //   const fetchCaptchaValue = async () => {
  //     try {
  //       const value = await DB.getCaptchaValue();
  //       setCaptchaVal(value.reCaptchaValue); // Assuming value is the data you want
  //     } catch (err) {
  //       setError('Failed to fetch captcha value');
  //       console.error(err);
  //     }
  //   };

  //   fetchCaptchaValue(); // Call the function
  // }, []);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set the reCAPTCHA response value
  };

  const handleCaptchaChange1 = (value) => {
    setCaptchaValue1(value); // Set the reCAPTCHA response value
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  /*
  const handleSubmit = () => {
    const newErrors = {};

    if (!formData.LegalName) newErrors.LegalName = 'Legal Name is mandatory';
    if (!formData.DBAName) newErrors.DBAName = 'DBA Name is mandatory';
    if (!formData.Address1) newErrors.Address1 = 'Address is mandatory';
    if (!formData.City) newErrors.City = 'City is mandatory';
    if (!formData.Country || formData.Country == 'Select Country') newErrors.Country = 'Country is mandatory';
    if (!formData.PhoneNumber) newErrors.PhoneNumber = 'Phone Number is mandatory';
    if (!formData.Email) newErrors.Email = 'Email is mandatory';
    if (!formData.BusinessCategory || formData.BusinessCategory == 'Select a category') newErrors.BusinessCategory = 'Business Category is mandatory';
    if (!formData.IsEcommerce || formData.IsEcommerce == 'Select an option') newErrors.IsEcommerce = 'Choose a valid option';
    if (!formData.SearchCriteria || formData.SearchCriteria == 'Select a Criteria') newErrors.SearchCriteria = 'Search Criteria is mandatory';
    if (!formData.CFirstName) newErrors.CFirstName = 'Contact First Name is mandatory';
    if (!formData.CLastName) newErrors.CLastName = 'Contact Last Name is mandatory';
    if (!formData.CAddress1) newErrors.CAddress1 = 'Contact Address is mandatory';
    if (!formData.CCity) newErrors.CCity = 'Contact City is mandatory';
    if (!formData.CCountry || formData.CCountry == 'Select Country') newErrors.CCountry = 'Contact Country is mandatory';

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors); //Settingh the error state to the new errors object
    } else {
      console.log(formData);
      setError({}); // Clearing errors if the form is valid
    }
  };
  */

  const handleSubmit = async () => {
    const newErrors = {};

    // Validation checks
    if (!formData.LegalName) newErrors.LegalName = 'Legal Name is mandatory';
    if (!formData.DBAName) newErrors.DBAName = 'DBA Name is mandatory';
    if (!formData.Address1) newErrors.Address1 = 'Address is mandatory';
    if (!formData.City) newErrors.City = 'City is mandatory';
    if (!formData.Country || formData.Country === 'Select Country') newErrors.Country = 'Country is mandatory';
    if (!formData.PhoneNumber) newErrors.PhoneNumber = 'Phone Number is mandatory';
    if (!formData.Email) newErrors.Email = 'Email is mandatory';
    if (!formData.BusinessCategory || formData.BusinessCategory === 'Select a category') newErrors.BusinessCategory = 'Business Category is mandatory';
    if (!formData.IsEcommerce || formData.IsEcommerce === 'Select an option') newErrors.IsEcommerce = 'Choose a valid option';
    if (!formData.SearchCriteria || formData.SearchCriteria === 'Select a Criteria') newErrors.SearchCriteria = 'Search Criteria is mandatory';
    // if (!formData.CFirstName) newErrors.CFirstName = 'Contact First Name is mandatory';
    // if (!formData.CLastName) newErrors.CLastName = 'Contact Last Name is mandatory';
    // if (!formData.CAddress1) newErrors.CAddress1 = 'Contact Address is mandatory';
    // if (!formData.CCity) newErrors.CCity = 'Contact City is mandatory';
    // if (!formData.CCountry || formData.CCountry === 'Select Country') newErrors.CCountry = 'Contact Country is mandatory';
    if (!formData.TotalNoContact) newErrors.TotalNoContact = 'This field is mandatory';
    if (!captchaValue) newErrors.captcha = 'Please complete the reCAPTCHA'; // Check if reCAPTCHA is completed

    const validateContact = (index) => {
      const prefix = index === 1 ? '' : index; // Handles prefix for first name, last name, etc.

      if (!formData[`CFirstName${prefix}`]) newErrors[`CFirstName${prefix}`] = 'Contact First Name is mandatory';
      if (!formData[`CLastName${prefix}`]) newErrors[`CLastName${prefix}`] = 'Contact Last Name is mandatory';
      if (!formData[`CAddress1${prefix}`]) newErrors[`CAddress1${prefix}`] = 'Contact Address is mandatory';
      if (!formData[`CCity${prefix}`]) newErrors[`CCity${prefix}`] = 'Contact City is mandatory';
      if (!formData[`CCountry${prefix}`] || formData[`CCountry${prefix}`] === 'Select Country') {
        newErrors[`CCountry${prefix}`] = 'Contact Country is mandatory';
      }
    };

    for (let i = 1; i <= totalNoContact; i++) {
      validateContact(i);
    }

    // Loop through the total number of contacts
    for (let i = 1; i <= totalNoContact; i++) {
      const prefix = i === 1 ? '' : i; // Determine the prefix for each contact

      // Push the contact's data into the principals array
      principals.push({
        first_name: formData[`CFirstName${prefix}`],
        middle_initial: formData[`CMiddleName${prefix}`] || "",
        last_name: formData[`CLastName${prefix}`],
        email: formData[`CMail${prefix}`],
        address: {
          address_line_1: formData[`CAddress1${prefix}`],
          address_line_2: formData[`CAddress2${prefix}`] || "",
          postal_code: formData[`CPSCode${prefix}`] || "",
          city: formData[`CCity${prefix}`],
          country: formData[`CCountry${prefix}`],
        },
        phone_number: formData[`CPhone${prefix}`] || "",
      });
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors); // Setting the error state to the new errors object
    } else {
      formData.APIResponse1 = "";
      formData.APIResponse2 = "";
      setLoading(true);

      // Generate random strings for partner_merchant_reference and partner_query_reference
      const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };

      var isEcom;

      formData.PQReference = generateRandomString(8);

      if (formData.IsEcommerce == 'Yes') {
        isEcom = true;
      } else {
        isEcom = false;
      }

      // const websites = formData.Web
      //   .split(",")
      //   .map((website) => `"${website.trim()}"`)
      //   .join(", ");
      const websites = formData.Web
        .split(",")
        .map((website) => website.trim())  // Remove leading/trailing spaces for each website
        .filter((website) => website.length > 0)  // Only include non-empty websites
        .map((website) => `"${website}"`)  // Format each website with quotes
        .join(", ");  // Join into a single string, with a comma separating

      // You can log the formatted result or send it to the backend
      console.log(websites);

      const phones = formData.PhoneNumber
        .split(",")
        .map((phone) => `"${phone.trim()}"`)
        .join(", ");

      // You can log the formatted result or send it to the backend
      console.log(phones);

      const uDetails = DB.getCurrentUserDetails();
      console.log(`The user details are: ${uDetails.email}`);

      console.log(`the urls are: ${websites}`);

      const requestData = {
        uDetail: uDetails.email ?? "",
        initiateRequest: {
          partner_merchant_reference: formData.PQReference + 'T',
          partner_query_reference: formData.PQReference,
          search_criteria: {
            search_area: formData.SearchCriteria,
          },
          queried_merchant: {
            legal_name: formData.LegalName,
            dba_name: formData.DBAName,
            address: {
              address_line_1: formData.Address1,
              address_line_2: formData.Address2 || "",
              city: formData.City,
              country: formData.Country,
              postal_code: formData.PostalCode || "",
            },
            // phone_numbers: [formData.PhoneNumber],
            phone_numbers: [phones],
            business_category: formData.BusinessCategory,
            principals: principals, // Use the constructed principals array
            // principals: [
            //   {
            //     first_name: formData.CFirstName,
            //     middle_initial: formData.CMiddleName || "",
            //     last_name: formData.CLastName,
            //     email: formData.CMail,
            //     address: {
            //       address_line_1: formData.CAddress1,
            //       address_line_2: formData.CAddress2 || "",
            //       postal_code: formData.CPSCode || "",
            //       city: formData.CCity,
            //       country: formData.CCountry,
            //     },
            //     phone_number: formData.CPhone || "",
            //   },
            // ],
            is_ecommerce: isEcom,
            email: formData.Email,
            // url: [formData.Web || ""]
            // url: [websites]
            // ...(websites ? { url: [websites] } : {})
            ...(websites.length > 0 ? { url: [websites] } : {}),
          }
        },
      };

      console.log(requestData);
      const bd = JSON.stringify(requestData);
      console.log(bd);

      try {
        const response = await fetch('https://us-central1-latpay-dev.cloudfunctions.net/rapydInitiateMerchantQuery', {
        // const response = await fetch('http://localhost:5000/latpay-dev/us-central1/rapydInitiateMerchantQuery', {
          method: 'POST',
          //mode: 'no-cors',
          // mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }

        const responseData = await response.json();
        console.log('Response from API:', responseData);
        console.log('Response from API:', JSON.stringify(responseData, null, 2));
        console.log('Partner query reference:', responseData.status?.response_code);
        if (responseData.status?.status === 'SUCCESS') {
          // formData.APIResponse1 = `Request Sent Successfully. The Reference Number is : ${responseData.data?.partner_query_reference}.`;
          alert(`Request Sent Successfully.\nThe Reference Number is : ${responseData.data?.partner_query_reference}.`);
          setError({}); // Clearing errors if the form is valid
        }
        else {
          // formData.APIResponse1 = `=>${JSON.stringify(responseData)}`;
          // formData.APIResponse1 = `Error sending Request.\n\nStatus: ${responseData.status?.status}\nError Code: ${responseData.status?.error_code}\nError Message: ${responseData.status?.message}`;
          alert(`Error sending Request.\n\nStatus: ${responseData.status?.status}\nError Code: ${responseData.status?.error_code}\nError Message: ${responseData.status?.message}`);
          setError({}); // Clearing errors if the form is valid
        }
      } catch (error) {
        console.error('Error sending request:', error);
        formData.APIResponse1 = error;
        setError({ submit: 'Failed to submit the form. Please try again.' });
      } finally {
        setLoading(false); // Stop loader
        // Refresh the reCAPTCHA
        recaptchaRef.current.reset();
        setCaptchaValue(null); // Reset the captcha value in state
      }
    }
  };

  const handleSubmit1 = async () => {

    const uDetails = DB.getCurrentUserDetails();
    console.log(`The user details are: ${uDetails.email}`);

    const newErrors = {};
    if (!formData.PQReference) newErrors.PQReference = 'Reference is mandatory';
    if (!captchaValue1) newErrors.captcha = 'Please complete the reCAPTCHA'; // Check if reCAPTCHA is completed

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors); //Settingh the error state to the new errors object
    } else {
      setLoading1(true);
      console.log(formData);

      const requestData = {
        uDetail: uDetails.email,
        pQRef: formData.PQReference,
      };

      try {
        const response = await fetch('https://us-central1-latpay-dev.cloudfunctions.net/rapydRetrieveQueryResult', {
        // const response = await fetch('http://localhost:5000/latpay-dev/us-central1/rapydRetrieveQueryResult', {
          method: 'POST',
          //mode: 'no-cors',
          // mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        // if (!response.ok) {
        //   throw new Error('Network response was not ok');
        // }

        const responseData = await response.json();
        console.log('Response from Retrieve API:', JSON.stringify(responseData, null, 2));
        // formData.APIResponse2 = JSON.stringify(responseData);
        switch (responseData.result) {
          case 'found':
            alert('Response received from Rapyd Successfully. Details will be emailed to compliance@latpay.com');
            break;
          case 'error':
            alert('No Response received from Rapyd. Please check with CRM Tech Team');
            break;
          case 'in progress':
            alert('Request still in progress. Please try after sometime');
            break;
          case 'not found':
            alert('No Request Found');
            break;
          default:
            alert('NA');
            break;
        }

        setError({}); // Clearing errors if the form is valid
      } catch (error) {
        console.error('Error sending request:', error);
        // formData.APIResponse2 = error;
        // alert('Error Sending Request From Host');
        setError({ submit: 'Failed to submit the form. Please try again.' });
      } finally {
        setLoading1(false); // Stop loader
        // Refresh the reCAPTCHA
        recaptchaRef.current.reset();
        setCaptchaValue1(null); // Reset the captcha value in state
      }
      //setError({}); // Clearing errors if the form is valid
    }
  };

  let formattedResponse1;
  try {
    const responseObject = JSON.parse(formData.APIResponse1);
    formattedResponse1 = JSON.stringify(responseObject, null, 2);
  } catch (error) {
    if (!error) {
      formattedResponse1 = error;
    } else {
      formattedResponse1 = '';
    }
  }

  let formattedResponse2;
  try {
    const responseObject = JSON.parse(formData.APIResponse2);
    formattedResponse2 = JSON.stringify(responseObject, null, 2);
  } catch (error) {
    if (!error) {
      formattedResponse2 = error;
    } else {
      formattedResponse2 = '';
    }
  }

  return (
    <div style={{ position: "relative", minHeight: "100vh", overflow: 'hidden' }}>
      <Container fluid style={{ padding: 0 }}>
        <Row>
          <Col
            style={{
              backgroundColor: "#00a1be",
              height: "143px",
              display: "flex",
              alignItems: "flex-end",
              padding: "10px",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute", // Positioning the overlay absolutely
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.5,
                backgroundImage: 'url(https://latpay.com/wp-content/themes/enfold/images/background-images/dots-mini-light.png)',
                backgroundRepeat: 'repeat',
                zIndex: 1, // Placing the overlay behind the content
              }}
            ></div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                zIndex: 2,
              }}
            /> */}
            <img
              src="https://www.rapyd.net/wp-content/uploads/2022/08/rapyd-logo-white.svg"
              alt="Logo"
              style={{
                height: "60px", // Matching this to the font size of the text
                marginRight: "10px", // Spacing between the logo and text
                zIndex: 2
              }}
            />
            <h1
              style={{
                color: "whitesmoke",
                fontSize: "50px",
                textAlign: "center",
                position: "relative", // Positioning the text above the overlay
                zIndex: 2, // Bringing the text in front of the overlay
              }}
            >
              - Card Network Lookup
            </h1>
          </Col>
        </Row>

        <Container style={{ marginTop: "75px", marginBottom: "75px" }}>
          <div style={{
            border: "1px solid #ccc", // Outline color
            borderRadius: "5px",      // Optional: rounded corners
            padding: "20px",          // Space inside the outline
            margin: "50px 0"          // Space outside the outline
          }}>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "40px" }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#253370"
                  }}>Initiate Merchant Query</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left" }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    // color: "#000000"
                  }}>Merchant Details</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Legal Name</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.LegalName}
                    name="LegalName"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={4}
                    maxLength={60}
                  />
                  {/* {error && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>} Error message */}
                  {error.LegalName && <div style={{ color: "red", fontSize: "12px" }}>{error.LegalName}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >DBA (Doing Business As) Name</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.DBAName}
                    name="DBAName"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={4}
                    maxLength={60}
                  />
                  {error.DBAName && <div style={{ color: "red", fontSize: "12px" }}>{error.DBAName}</div>} {/* Error message for this field */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Address Line 1</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.Address1}
                    name="Address1"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={1}
                    maxLength={60}
                  />
                  {error.Address1 && <div style={{ color: "red", fontSize: "12px" }}>{error.Address1}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Address Line 2</Form.Label>{" "}

                  <Form.Control
                    type="text"
                    value={formData.Address2}
                    name="Address2"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={1}
                    maxLength={60}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >City</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.City}
                    name="City"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={1}
                    maxLength={20}
                  />
                  {error.City && <div style={{ color: "red", fontSize: "12px" }}>{error.City}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >
                    Country
                  </Form.Label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Form.Select
                    value={formData.Country}
                    name="Country"
                    onChange={handleChange}
                    style={{
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  >
                    <option value="">Select Country</option>
                    <option value="GB">United Kingdom</option>
                    <option value="AU">Australia</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BR">Brazil</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="CV">Cabo Verde</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo (Congo-Brazzaville)</option>
                    <option value="CR">Costa Rica</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="CD">Democratic Republic of the Congo</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="SZ">Eswatini (fmr. "Swaziland")</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GR">Greece</option>
                    <option value="GD">Grenada</option>
                    <option value="GT">Guatemala</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HN">Honduras</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">Korea (North)</option>
                    <option value="KR">Korea (South)</option>
                    <option value="XK">Kosovo</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Laos</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia</option>
                    <option value="MD">Moldova</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="MK">North Macedonia</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="QA">Qatar</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russia</option>
                    <option value="RW">Rwanda</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syria</option>
                    <option value="TW">Taiwan</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="US">United States</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VA">Vatican City</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Vietnam</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </Form.Select>
                  {error.Country && <div style={{ color: "red", fontSize: "12px" }}>{error.Country}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Postal Code</Form.Label>{" "}

                  <Form.Control
                    type="text"
                    value={formData.PSCode}
                    name="PSCode"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={1}
                    maxLength={10}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Phone Number</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.PhoneNumber}
                    name="PhoneNumber"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    placeholder="e.g. +44123456789"
                    className="phone-input"
                    minLength={7}
                    maxLength={100}
                  />
                  {error.PhoneNumber && <div style={{ color: "red", fontSize: "12px" }}>{error.PhoneNumber}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Company Email</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.Email}
                    name="Email"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    placeholder="e.g. sample@mail.com"
                    className="phone-input"
                    minLength={1}
                    maxLength={90}
                  />
                  {!isValidEmail(formData.Email) && formData.Email && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      Please enter a valid email address.
                    </div>
                  )}
                  {error.Email && <div style={{ color: "red", fontSize: "12px" }}>{error.Email}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Website</Form.Label>{" "}
                  <Form.Control
                    type="text"
                    placeholder="e.g. https://testpage.com, https://testpage1.com, "
                    className="phone-input"
                    value={formData.Web}
                    name="Web"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={1}
                    maxLength={100}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >
                    Business Category
                  </Form.Label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Form.Select
                    value={formData.BusinessCategory}
                    name="BusinessCategory"
                    onChange={handleChange}
                    style={{
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  >
                    <option value="">Select a category</option>
                    <option value="Merchant">Merchant</option>
                    <option value="Payment Facilitator">Payment Facilitator</option>
                    <option value="Independent Sales Organization">Independent Sales Organization</option>
                    <option value="Marketplace">Marketplace</option>
                    <option value="Staged Digital Wallet Operator">Staged Digital Wallet Operator</option>
                    <option value="Sponsored Merchant">Sponsored Merchant</option>
                  </Form.Select>
                  {error.BusinessCategory && <div style={{ color: "red", fontSize: "12px" }}>{error.BusinessCategory}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >
                    Merchant trades through Internet (EComm)?
                  </Form.Label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Form.Select
                    value={formData.IsEcommerce}
                    name="IsEcommerce"
                    onChange={handleChange}
                    style={{
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  >
                    <option value="">Select an option</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Form.Select>
                  {error.IsEcommerce && <div style={{ color: "red", fontSize: "12px" }}>{error.IsEcommerce}</div>} {/* Error message for this field */}
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >
                    Search Criteria
                  </Form.Label>{" "}
                  <span style={{ color: "red" }}>*</span>
                  <Form.Select
                    value={formData.SearchCriteria}
                    name="SearchCriteria"
                    onChange={handleChange}
                    style={{
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  >
                    <option value="">Select a Criteria</option>
                    <option value="global">Global</option>
                    <option value="local">Local</option>
                    <option value="regional">Regional</option>
                  </Form.Select>
                  {error.SearchCriteria && <div style={{ color: "red", fontSize: "12px" }}>{error.SearchCriteria}</div>} {/* Error message for this field */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left" }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    // color: "#253370"
                  }}>Principals Details</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px", width: "32%" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Total Number of Contacts</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="number"
                    value={formData.TotalNoContact}
                    name="TotalNoContact"
                    onChange={handleChange}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                    minLength={1}
                    maxLength={1}
                  />
                  {error.TotalNoContact && <div style={{ color: "red", fontSize: "12px" }}>{error.TotalNoContact}</div>} {/* Error message for this field */}
                </div>
              </Col>
            </Row>
            {/* {Array.from({ length: numContacts }).map((_, index) => ( */}
            {/* <Row key={index}> */}
            {totalNoContact >= 1 && (
              <Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left" }}>
                      <p style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "HelveticaNeue, sans-serif",
                        // color: "#253370"
                      }}>Contact Details 1</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >First Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CFirstName}
                        name={`CFirstName`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={35}
                      />
                      {error.CFirstName && <div style={{ color: "red", fontSize: "12px" }}>{error.CFirstName}</div>}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Middle Name</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMiddleName}
                        name={`CMiddleName`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        maxLength={1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Last Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CLastName}
                        name={`CLastName`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={40}
                      />
                      {error.CLastName && <div style={{ color: "red", fontSize: "12px" }}>{error.CLastName}</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Number</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPhone}
                        name="CPhone"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. +44123456789"
                        className="phone-input"
                        minLength={7}
                        maxLength={18}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Email</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMail}
                        name="CMail"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. sample@mail.com"
                        className="phone-input"
                        maxLength={90}
                      />
                      {!isValidEmail(formData.CMail) && formData.CMail && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 1</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CAddress1}
                        name="CAddress1"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                      {error.CAddress1 && <div style={{ color: "red", fontSize: "12px" }}>{error.CAddress1}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 2</Form.Label>{" "}

                      <Form.Control
                        type="text"
                        value={formData.CAddress2}
                        name="CAddress2"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >City</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CCity}
                        name="CCity"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={20}
                      />
                      {error.CCity && <div style={{ color: "red", fontSize: "12px" }}>{error.CCity}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >
                        Country
                      </Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Select
                        value={formData.CCountry}
                        name="CCountry"
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                      >
                        <option value="">Select Country</option>
                        <option value="GB">United Kingdom</option>
                        <option value="AU">Australia</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brazil</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Congo-Brazzaville)</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CD">Democratic Republic of the Congo</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (fmr. "Swaziland")</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GR">Greece</option>
                        <option value="GD">Grenada</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (North)</option>
                        <option value="KR">Korea (South)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="MK">North Macedonia</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </Form.Select>
                      {error.CCountry && <div style={{ color: "red", fontSize: "12px" }}>{error.CCountry}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Post Code</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPSCode}
                        name="CPSCode"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={10}
                      />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
            {totalNoContact >= 2 && (
              <Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left" }}>
                      <p style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "HelveticaNeue, sans-serif",
                        // color: "#253370"
                      }}>Contact Details 2</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >First Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CFirstName2}
                        name={`CFirstName2`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={35}
                      />
                      {error.CFirstName2 && <div style={{ color: "red", fontSize: "12px" }}>{error.CFirstName2}</div>}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Middle Name</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMiddleName2}
                        name={`CMiddleName2`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        maxLength={1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Last Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CLastName2}
                        name={`CLastName2`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={40}
                      />
                      {error.CLastName2 && <div style={{ color: "red", fontSize: "12px" }}>{error.CLastName2}</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Number</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPhone2}
                        name="CPhone2"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. +44123456789"
                        className="phone-input"
                        minLength={7}
                        maxLength={18}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Email</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMail2}
                        name="CMail2"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. sample@mail.com"
                        className="phone-input"
                        maxLength={90}
                      />
                      {!isValidEmail(formData.CMail2) && formData.CMail2 && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 1</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CAddress12}
                        name="CAddress12"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                      {error.CAddress12 && <div style={{ color: "red", fontSize: "12px" }}>{error.CAddress12}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 2</Form.Label>{" "}

                      <Form.Control
                        type="text"
                        value={formData.CAddress22}
                        name="CAddress22"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >City</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CCity2}
                        name="CCity2"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={20}
                      />
                      {error.CCity2 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCity2}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >
                        Country
                      </Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Select
                        value={formData.CCountry2}
                        name="CCountry2"
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                      >
                        <option value="">Select Country</option>
                        <option value="GB">United Kingdom</option>
                        <option value="AU">Australia</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brazil</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Congo-Brazzaville)</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CD">Democratic Republic of the Congo</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (fmr. "Swaziland")</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GR">Greece</option>
                        <option value="GD">Grenada</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (North)</option>
                        <option value="KR">Korea (South)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="MK">North Macedonia</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </Form.Select>
                      {error.CCountry2 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCountry2}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Post Code</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPSCode2}
                        name="CPSCode2"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={10}
                      />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
            {totalNoContact >= 3 && (
              <Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left" }}>
                      <p style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "HelveticaNeue, sans-serif",
                        // color: "#253370"
                      }}>Contact Details 3</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >First Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CFirstName3}
                        name={`CFirstName3`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={35}
                      />
                      {error.CFirstName3 && <div style={{ color: "red", fontSize: "12px" }}>{error.CFirstName3}</div>}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Middle Name</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMiddleName3}
                        name={`CMiddleName3`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        maxLength={1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Last Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CLastName3}
                        name={`CLastName3`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={40}
                      />
                      {error.CLastName3 && <div style={{ color: "red", fontSize: "12px" }}>{error.CLastName3}</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Number</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPhone3}
                        name="CPhone3"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. +44123456789"
                        className="phone-input"
                        minLength={7}
                        maxLength={18}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Email</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMail3}
                        name="CMail3"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. sample@mail.com"
                        className="phone-input"
                        maxLength={90}
                      />
                      {!isValidEmail(formData.CMail3) && formData.CMail3 && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 1</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CAddress13}
                        name="CAddress13"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                      {error.CAddress13 && <div style={{ color: "red", fontSize: "12px" }}>{error.CAddress13}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 2</Form.Label>{" "}

                      <Form.Control
                        type="text"
                        value={formData.CAddress23}
                        name="CAddress23"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >City</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CCity3}
                        name="CCity3"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={20}
                      />
                      {error.CCity3 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCity3}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >
                        Country
                      </Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Select
                        value={formData.CCountry3}
                        name="CCountry3"
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                      >
                        <option value="">Select Country</option>
                        <option value="GB">United Kingdom</option>
                        <option value="AU">Australia</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brazil</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Congo-Brazzaville)</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CD">Democratic Republic of the Congo</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (fmr. "Swaziland")</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GR">Greece</option>
                        <option value="GD">Grenada</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (North)</option>
                        <option value="KR">Korea (South)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="MK">North Macedonia</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </Form.Select>
                      {error.CCountry3 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCountry3}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Post Code</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPSCode3}
                        name="CPSCode3"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={10}
                      />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
            {totalNoContact >= 4 && (
              <Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left" }}>
                      <p style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "HelveticaNeue, sans-serif",
                        // color: "#253370"
                      }}>Contact Details 4</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >First Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CFirstName4}
                        name={`CFirstName4`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={35}
                      />
                      {error.CFirstName4 && <div style={{ color: "red", fontSize: "12px" }}>{error.CFirstName4}</div>}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Middle Name</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMiddleName4}
                        name={`CMiddleName4`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        maxLength={1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Last Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CLastName4}
                        name={`CLastName4`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={40}
                      />
                      {error.CLastName4 && <div style={{ color: "red", fontSize: "12px" }}>{error.CLastName4}</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Number</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPhone4}
                        name="CPhone4"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. +44123456789"
                        className="phone-input"
                        minLength={7}
                        maxLength={18}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Email</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMail4}
                        name="CMail4"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. sample@mail.com"
                        className="phone-input"
                        maxLength={90}
                      />
                      {!isValidEmail(formData.CMail4) && formData.CMail4 && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 1</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CAddress14}
                        name="CAddress14"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                      {error.CAddress14 && <div style={{ color: "red", fontSize: "12px" }}>{error.CAddress14}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 2</Form.Label>{" "}

                      <Form.Control
                        type="text"
                        value={formData.CAddress24}
                        name="CAddress24"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >City</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CCity4}
                        name="CCity4"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={20}
                      />
                      {error.CCity4 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCity4}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >
                        Country
                      </Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Select
                        value={formData.CCountry4}
                        name="CCountry4"
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                      >
                        <option value="">Select Country</option>
                        <option value="GB">United Kingdom</option>
                        <option value="AU">Australia</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brazil</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Congo-Brazzaville)</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CD">Democratic Republic of the Congo</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (fmr. "Swaziland")</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GR">Greece</option>
                        <option value="GD">Grenada</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (North)</option>
                        <option value="KR">Korea (South)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="MK">North Macedonia</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </Form.Select>
                      {error.CCountry4 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCountry4}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Post Code</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPSCode4}
                        name="CPSCode4"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={10}
                      />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
            {totalNoContact === 5 && (
              <Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left" }}>
                      <p style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "HelveticaNeue, sans-serif",
                        // color: "#253370"
                      }}>Contact Details 5</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >First Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CFirstName5}
                        name={`CFirstName5`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={35}
                      />
                      {error.CFirstName5 && <div style={{ color: "red", fontSize: "12px" }}>{error.CFirstName5}</div>}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Middle Name</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMiddleName5}
                        name={`CMiddleName5`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        maxLength={1}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Last Name</Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CLastName5}
                        name={`CLastName5`}
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={40}
                      />
                      {error.CLastName5 && <div style={{ color: "red", fontSize: "12px" }}>{error.CLastName5}</div>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Number</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPhone5}
                        name="CPhone5"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. +44123456789"
                        className="phone-input"
                        minLength={7}
                        maxLength={18}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Contact Email</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CMail5}
                        name="CMail5"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        placeholder="e.g. sample@mail.com"
                        className="phone-input"
                        maxLength={90}
                      />
                      {!isValidEmail(formData.CMail5) && formData.CMail5 && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          Please enter a valid email address.
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 1</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CAddress15}
                        name="CAddress15"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                      {error.CAddress15 && <div style={{ color: "red", fontSize: "12px" }}>{error.CAddress15}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Address Line 2</Form.Label>{" "}

                      <Form.Control
                        type="text"
                        value={formData.CAddress25}
                        name="CAddress25"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={60}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >City</Form.Label>{" "}
                      <span style={{ color: "red " }}>*</span>
                      <Form.Control
                        type="text"
                        value={formData.CCity5}
                        name="CCity5"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={20}
                      />
                      {error.CCity5 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCity5}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >
                        Country
                      </Form.Label>{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Form.Select
                        value={formData.CCountry5}
                        name="CCountry5"
                        onChange={handleChange}
                        style={{
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                      >
                        <option value="">Select Country</option>
                        <option value="GB">United Kingdom</option>
                        <option value="AU">Australia</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BR">Brazil</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Congo-Brazzaville)</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CD">Democratic Republic of the Congo</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (fmr. "Swaziland")</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GR">Greece</option>
                        <option value="GD">Grenada</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (North)</option>
                        <option value="KR">Korea (South)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="MK">North Macedonia</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </Form.Select>
                      {error.CCountry5 && <div style={{ color: "red", fontSize: "12px" }}>{error.CCountry5}</div>} {/* Error message for this field */}
                    </div>
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left", marginBottom: "25px" }}>
                      <Form.Label
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                          color: "#666666"
                        }}
                      >Post Code</Form.Label>{" "}
                      <Form.Control
                        type="text"
                        value={formData.CPSCode5}
                        name="CPSCode5"
                        onChange={handleChange}
                        style={{
                          // fontWeight: "bold",
                          fontSize: "12px",
                          fontFamily: "HelveticaNeue, sans-serif",
                        }}
                        minLength={1}
                        maxLength={10}
                      />
                    </div>
                  </Col>
                </Row>
              </Row>
            )}
            {/* ))} */}
            <Row>
              <Col>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lfdr2cqAAAAABN1wUnWV3RzkM2nhhLFkl26Q887" // Replace with your reCAPTCHA site key
                  onChange={handleCaptchaChange}
                />
                {error.captcha && <div style={{ color: "red", fontSize: "12px" }}>{error.captcha}</div>} {/* Error message for reCAPTCHA */}
              </Col>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#00a1be",
                  borderColor: "#00a1be",
                  color: "white",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                  marginBottom: "25px"
                }}
                // onClick={() => {
                //   console.log(formData);
                // }}
                onClick={handleSubmit}
                //disabled={loading}
                disabled={loading || !captchaValue} // Disable button if loading or captcha is not completed
              >
                {loading ? 'Loading...' : 'Send Request'}
              </Button>
              {" "}
              {loading && <div className="spinner" style={{ marginLeft: '10px', alignSelf: 'center' }}></div>}
            </div>
            {/* <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    // color: "#253370"
                  }}>Response</p>
                </div>
              </Col>
            </Row> */}
            {/* <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "35px" }}>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    // value="This is test"
                    // value={formData.APIResponse1}
                    value={formattedResponse1}
                    name="APIResponse1"
                    onChange={handleChange}
                    readOnly
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  />
                  {error.APIResponse1 && <div style={{ color: "red", fontSize: "12px" }}>{error.APIResponse1}</div>}
                </div>
              </Col>
            </Row> */}
          </div>
          <div style={{
            border: "1px solid #ccc", // Outline color
            borderRadius: "5px",      // Optional: rounded corners
            padding: "20px",          // Space inside the outline
            margin: "5px 0"          // Space outside the outline
          }}>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "40px" }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    color: "#253370"
                  }}>Retrieve Query Result</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px", width: "49%" }}>
                  <Form.Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                      color: "#666666"
                    }}
                  >Partner Query Reference</Form.Label>{" "}
                  <span style={{ color: "red " }}>*</span>
                  <Form.Control
                    type="text"
                    value={formData.PQReference}
                    name="PQReference"
                    onChange={handleChange1}
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  />
                  {error.PQReference && <div style={{ color: "red", fontSize: "12px" }}>{error.PQReference}</div>} {/* Error message for this field */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lfdr2cqAAAAABN1wUnWV3RzkM2nhhLFkl26Q887" // Replace with your reCAPTCHA site key
                  onChange={handleCaptchaChange1}
                />
                {error.captcha && <div style={{ color: "red", fontSize: "12px" }}>{error.captcha}</div>} {/* Error message for reCAPTCHA */}
              </Col>
            </Row>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#00a1be",
                  borderColor: "#00a1be",
                  color: "white",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                  marginBottom: "25px"
                }}
                // onClick={() => {
                //   console.log(formData);
                // }}
                onClick={handleSubmit1}
                disabled={loading1}
              >
                {loading1 ? 'Loading...' : 'Send Request'}
              </Button>
              {" "}
              {loading1 && <div className="spinner" style={{ marginLeft: '10px', alignSelf: 'center' }}></div>}
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#00a1be",
                  borderColor: "#00a1be",
                  color: "white",
                  padding: "10px 25px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                  marginBottom: "25px"
                }}
                onClick={handleSubmit1}
                disabled={loading1 || !captchaValue1} // Disable button if loading or captcha is not completed
              >
                {loading1 ? 'Loading...' : 'Send Request'}
              </Button>
              {" "}
              {loading1 && <div className="spinner" style={{ marginLeft: '10px', alignSelf: 'center' }}></div>}
            </div>
            {/* <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "25px" }}>
                  <p style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontFamily: "HelveticaNeue, sans-serif",
                    // color: "#253370"
                  }}>Response</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: "left", marginBottom: "35px" }}>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    // value="This is test"
                    // value={formData.APIResponse2}
                    // value={JSON.stringify(JSON.parse(formData.APIResponse2), null, 2)} // Formatting JSON with indentation
                    value={formattedResponse2}
                    name="APIResponse2"
                    onChange={handleChange1}
                    readOnly
                    style={{
                      // fontWeight: "bold",
                      fontSize: "12px",
                      fontFamily: "HelveticaNeue, sans-serif",
                    }}
                  />
                </div>
              </Col>
            </Row> */}
          </div>
          <p style={{ color: "black", fontSize: "12px", marginBottom: "5px", textAlign: "left" }}>
            <a style={{ textDecoration: "none", color: "red" }}>* Required Field</a>
          </p>
        </Container>
        {/* <Row className="mt-auto">
          <Col
            style={{
              backgroundColor: "#122051",

              padding: "20px",
              justifyContent: "center",
            }}
          >
            <p
              style={{ color: "whitesmoke", fontSize: "11px", marginBottom: "5px" }}
            >
              All Content Copyright © 2024 Latpay | <a href="https://latpay.com/privacy-policy" target="_blank" style={{ textDecoration: "none", color: "#ffffff" }}>Privacy Policy</a> | <a href="https://latpay.com/terms-condition/" target="_blank" style={{ textDecoration: "none", color: "#ffffff" }}>Terms &amp; Conditions</a> | <a href="https://latpay.com/wp-content/uploads/2023/11/Financial-Services-Guide-and-Product-Disclosure-Statement-final-V1.1-14.03.23.pdf" target="_blank" style={{ textDecoration: "none", color: "#ffffff" }}>AU Financial Services Guide</a>
            </p>
            <p
              style={{ color: "whitesmoke", fontSize: "11px", marginBottom: "auto" }}
            >
              Lateral Payment Solutions Pty Ltd (Latpay) ABN 12 610 150 064 is the holder of an Australian Financial Services Licence (AFSL) No. 521901.
            </p>
          </Col>
        </Row>

        <Row>
          <Col
            className="d-flex justify-content-center"
            style={{ backgroundColor: "#fff", padding: "10px" }}
          >
            <img
              src="https://latpay.com/wp-content/uploads/2024/03/logos2.png"
              alt=""
              style={{ height: "50px" }}
            />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default CardNetworkLookup;